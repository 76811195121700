.dash {
  display: flex;
}
.Dasbtn {
  width: 90%;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
}
.Dasbtn:hover {
  background-color: rgb(168, 167, 167);
  margin-left: -8px;
}
#eet {
  transition: all 0.9s ease-in-out;
}
#ett:hover {
  transition: all 0.5s ease-in;
  margin-top: -10px;
  margin-left: -10px;
}
#fi {
  position: fixed;
  position: sticky;
  top: 13vh;
}

.burger button {
  border: none;
  color: white;
  background-color: transparent;
  font-size: 29px;
  outline: none;
}
.messagess {
  position: relative;
  font-size: 25px;
}
.num {
  height: 21px;
  font-size: 12px;
  width: 25px;
  display: grid;
  place-items: center;
  position: absolute;
  top: -1px;
  right: 6px;
  border-radius: 8px;
}
.round {
  height: 50px;
  background-color: rgb(201, 201, 201);
  width: 50px;
}
#hoverEffect {
  transition: all 0.3s ease-in-out;
}
#hoverEffect:hover {
  background-color: #2d1455;
  color: white;
}
#timeLineCon::after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #984779;
  position: relative;
  left: -8%;
  border-radius: 50%;
  top: 46%;
}
#tab td {
  padding-top: 12px;
  padding-bottom: 12px;
}
#card {
  margin-left: -10px;
}
#card::before {
  content: "";
  height: 115px;
  width: 1px;
  border-left: #984779 dotted 1px;
  position: absolute;
  left: 7%;
  opacity: 0.3;
}
#card::after {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #984779;
  position: absolute;
  left: 6.3%;
  top: 25%;
}
@media (max-width: 800px) {
  #card::after {
    left: 6.5%;
  }
  .messagess {
    margin-left: -8%;
  }
  div {
    padding-left: 0px;
    margin-right: 0px;
  }
  #dash2 {
    height: 880px;
  }
  #tex {
    display: none;
  }
  .burger {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
  .burgers {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
  #dash3 {
    position: none;
    /* display: none; */
  }
  .dblock{
    display: block;
  }

  .dnone {
    display: none;
  }
  #fi {
    background: rgba(0, 0, 0, 0.904);
    box-shadow: none;
    position: fixed;
    z-index: 2;
    transition: all 0.6s ease-in-out;
    top: 13vh;
    width: 85%;
  }
  .Dasbtn {
    background-color: transparent;
    color: white;
    font-size: 18px;
  }
  .Dasbtn:hover {
    background-color: #1451b9;
  }
}
@media (max-width: 600px) {
  #card p {
    font-size: 11px !important;
  }
  #card::after {
    left: 5.5%;
  }
}
